import { createContext, PropsWithChildren, useContext, useState } from 'react';

const SEARCH_INIT = {
  serchType: '1',
  setSerchType: () => {},
};

const SerchTypeContext = createContext<{
  serchType: string;
  setSerchType: React.Dispatch<React.SetStateAction<string>>;
}>(SEARCH_INIT);

export const useSerchTypeContext = () => {
  const serchType = useContext(SerchTypeContext);

  if (!serchType)
    throw new Error('SearchTypeContextProvider를 찾을 수 없습니다!');

  return serchType;
};

export default function VINumContextProvider({ children }: PropsWithChildren) {
  const [serchType, setSerchType] = useState('1');

  return (
    <SerchTypeContext.Provider value={{ serchType, setSerchType }}>
      {children}
    </SerchTypeContext.Provider>
  );
}
