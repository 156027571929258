import './global.css';

import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/client';

import AplloProviderWrapper from './AplloProviderWrapper';
import {
  EditingCarProvider,
  InspectionItemProvider,
  IsInspectionRouteProvider,
  PaginationContextProvider,
  SearchTypeContextProvider,
  VINumContextProvider,
} from './Context';
import Router from './Router';

type Context = (Props: PropsWithChildren) => JSX.Element;

interface IAppProvider {
  contexts: Context[];
  children: JSX.Element;
}

const AppProvider = ({ contexts, children }: IAppProvider) =>
  contexts.reduce(
    (prev, context) => React.createElement(context, null, prev),
    children,
  );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
  }

  return <Router />;
}

root.render(
  <AplloProviderWrapper>
    <AppProvider
      contexts={[
        EditingCarProvider,
        VINumContextProvider,
        PaginationContextProvider,
        InspectionItemProvider,
        IsInspectionRouteProvider,
        SearchTypeContextProvider,
      ]}
    >
      <App />
    </AppProvider>
  </AplloProviderWrapper>,
);
