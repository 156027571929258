import { Navigate, Outlet } from 'react-router-dom';

import { KEY } from '@/constants/localstorageKey';
import { PATH } from '@/constants/path';
import { useVINumContext } from '@/Context/VINumContext';

export const Private = () => {
  const jwtToken =
    localStorage.getItem(KEY.JWT_TOKEN) ??
    sessionStorage.getItem(KEY.JWT_TOKEN);

  const expireDate = localStorage.getItem(KEY.JWT_TOKEN_EXPIRE);

  if (expireDate) {
    const expire = new Date(expireDate);

    if (new Date() > expire) {
      localStorage.removeItem(KEY.JWT_TOKEN);
      localStorage.removeItem(KEY.JWT_TOKEN_EXPIRE);
      sessionStorage.removeItem(KEY.JWT_TOKEN);

      return <Navigate to={PATH.LOGIN} />;
    }
  }

  return jwtToken ? <Outlet /> : <Navigate to={PATH.LOGIN} />;
};

export const Public = () => {
  const jwtToken =
    localStorage.getItem(KEY.JWT_TOKEN) ??
    sessionStorage.getItem(KEY.JWT_TOKEN);

  const expireDate = localStorage.getItem(KEY.JWT_TOKEN_EXPIRE);

  if (expireDate) {
    const expire = new Date(expireDate);

    if (new Date() > expire) {
      localStorage.removeItem(KEY.JWT_TOKEN);
      localStorage.removeItem(KEY.JWT_TOKEN_EXPIRE);
      sessionStorage.removeItem(KEY.JWT_TOKEN);

      return <Navigate to={PATH.LOGIN} />;
    }
  }

  return jwtToken ? <Navigate to={PATH.HOME} /> : <Outlet />;
};

export const IsVINum = () => {
  const { VINum } = useVINumContext();

  return VINum ? <Outlet /> : <Navigate to={PATH.HOME} />;
};
