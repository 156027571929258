import { createContext, PropsWithChildren, useContext, useState } from 'react';

const INIT = {
  isInspectionRoute: false,
  setIsInspectionRoute: () => {},
};

const isInspectionRouteContext = createContext<{
  isInspectionRoute: boolean;
  setIsInspectionRoute: React.Dispatch<React.SetStateAction<boolean>>;
}>(INIT);

export const useIsInspectionRouteContext = () => {
  const isInspectionRoute = useContext(isInspectionRouteContext);

  if (!isInspectionRoute) throw new Error('isInspectionRouteContext not found');

  return isInspectionRoute;
};

export default function IsInspectionRouteProvider({
  children,
}: PropsWithChildren) {
  const [isInspectionRoute, setIsInspectionRoute] = useState(false);

  return (
    <isInspectionRouteContext.Provider
      value={{ isInspectionRoute, setIsInspectionRoute }}
    >
      {children}
    </isInspectionRouteContext.Provider>
  );
}
