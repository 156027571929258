import { ApolloProvider } from '@apollo/client';
import { ApolloClient, from, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { PropsWithChildren } from 'react';

import { KEY } from './constants/localstorageKey';

export default function AplloProviderWrapper({ children }: PropsWithChildren) {
  const httpLink = createUploadLink({
    uri: URI,
  });

  const authLink = setContext((_, { headers }) => {
    const token =
      sessionStorage.getItem(KEY.JWT_TOKEN) ??
      localStorage.getItem(KEY.JWT_TOKEN);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const link = from([authLink, httpLink]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-first',
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

let URI = '';

if (process.env.NODE_ENV === 'development') {
  URI = 'https://stagegraphql.azurewebsites.net/graphql/';
} else {
  URI = 'https://chatapi.mangoworldcar.com/graphql/';
}
