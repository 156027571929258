import { createContext, PropsWithChildren, useContext, useState } from 'react';

const VINUM_INIT = {
  VINum: '',
  setVINum: () => {},
};

const VINumContext = createContext<{
  VINum: string;
  setVINum: React.Dispatch<React.SetStateAction<string>>;
}>(VINUM_INIT);

export const useVINumContext = () => {
  const VINum = useContext(VINumContext);

  if (!VINum) throw new Error('VINumContextProvider를 찾을 수 없습니다!');

  return VINum;
};

export default function VINumContextProvider({ children }: PropsWithChildren) {
  const [VINum, setVINum] = useState('');

  return (
    <VINumContext.Provider value={{ VINum, setVINum }}>
      {children}
    </VINumContext.Provider>
  );
}
