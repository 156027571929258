export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  PERFORMANCE_CHECK: '/PerformanceCheck',
  INSPECTION_HISTORY: '/InspectionHistory',
  INSPECTION_HISTORY_WORK_STASH: '/InspectionHistory/WorkStash',
  CAR_BASIC_INFO: '/PerformanceCheck/basicInfo',
  CAR_INTERIOR_INFO: '/PerformanceCheck/interiorInfo',
  CAR_EXTERIOR_INFO: '/PerformanceCheck/exteriorInfo',
  CAR_ACCIDENT_INFO: '/PerformanceCheck/accidentInfo',
  CAR_ETC_INFO: '/PerformanceCheck/etcInfo',
};
