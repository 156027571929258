import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { IMITCarItems } from '@/graphql/INSPECTION_SEARCH';

const INIT = {
  inspectionItems: [] as IMITCarItems[],
  setInspectionItems: () => {},
};

const InspectionItemContext = createContext<{
  inspectionItems: IMITCarItems[];
  setInspectionItems: React.Dispatch<React.SetStateAction<IMITCarItems[]>>;
}>(INIT);

export const useInspectionItemContext = () => {
  const InspectionItem = useContext(InspectionItemContext);

  if (!InspectionItem) throw new Error('InspectionItemContext not found');

  return InspectionItem;
};

export default function InspectionItemProvider({
  children,
}: PropsWithChildren) {
  const [inspectionItems, setInspectionItems] = useState<IMITCarItems[]>([]);

  return (
    <InspectionItemContext.Provider
      value={{ inspectionItems, setInspectionItems }}
    >
      {children}
    </InspectionItemContext.Provider>
  );
}
