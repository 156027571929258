import {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

const INCREASE = 'INCREASE' as const;
const DECREASE = 'DECREASE' as const;
const SETDEFF = 'SETDEFF' as const;

const increasePageNumber = () => ({ type: INCREASE });
const decreasePageNumber = () => ({ type: DECREASE });
const setDefaultPageNumber = (diff: number) => ({
  type: SETDEFF,
  payload: diff,
});

type ActionType =
  | ReturnType<typeof increasePageNumber>
  | ReturnType<typeof decreasePageNumber>
  | ReturnType<typeof setDefaultPageNumber>;

const INIT_CURRENT_PAGE_NUMBER = 1;

function reducer(state: number = INIT_CURRENT_PAGE_NUMBER, action: ActionType) {
  switch (action.type) {
    case INCREASE:
      return state + 1;
    case DECREASE:
      return state - 1 < INIT_CURRENT_PAGE_NUMBER
        ? INIT_CURRENT_PAGE_NUMBER
        : state - 1;
    case SETDEFF:
      return action.payload;
    default:
      return state;
  }
}

const INIT_PAGINATION = {
  currentPageNumber: INIT_CURRENT_PAGE_NUMBER,
  increasePageNum: () => {},
  decreasePageNum: () => {},
  setPageNum: (diff: number) => {},
};

const paginationContext = createContext(INIT_PAGINATION);

export const usePaginationContext = () => {
  const pagenumberContext = useContext(paginationContext);

  if (!paginationContext) throw new Error('Cannot find PagenationProvider');

  return pagenumberContext;
};

export default function PaginationProvider({ children }: PropsWithChildren) {
  const [currentPageNumber, dispatch] = useReducer(
    reducer,
    INIT_CURRENT_PAGE_NUMBER,
  );

  const increasePageNum = () => dispatch(increasePageNumber());
  const decreasePageNum = () => dispatch(decreasePageNumber());
  const setPageNum = (diff: number) => dispatch(setDefaultPageNumber(diff));

  return (
    <paginationContext.Provider
      value={{
        currentPageNumber,
        increasePageNum,
        decreasePageNum,
        setPageNum,
      }}
    >
      {children}
    </paginationContext.Provider>
  );
}
