import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Loader from '@/components/Lodader';
import { PATH } from '@/constants/path';

import { IsVINum, Private, Public } from './Vaildation';

export default function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<Public />}>
            <Route element={<Login />} path={PATH.LOGIN} />
          </Route>
          <Route element={<Private />}>
            <Route element={<Home />} path={PATH.HOME} />
            <Route
              element={<PerformanceCheck />}
              path={PATH.PERFORMANCE_CHECK}
            />
            <Route element={<IsVINum />}>
              <Route element={<CarBasicInfo />} path={PATH.CAR_BASIC_INFO} />
              <Route
                element={<CarInteriorInfo />}
                path={PATH.CAR_INTERIOR_INFO}
              />
              <Route
                element={<CarExteriorInfo />}
                path={PATH.CAR_EXTERIOR_INFO}
              />
              <Route
                element={<StatusAssessment />}
                path={PATH.CAR_ACCIDENT_INFO}
              />
              <Route
                element={<AdditionalInformation />}
                path={PATH.CAR_ETC_INFO}
              />
            </Route>
            <Route
              element={<InspectionHistory />}
              path={PATH.INSPECTION_HISTORY}
            />
            <Route
              element={<InspectionHistoryWorkStash />}
              path={PATH.INSPECTION_HISTORY_WORK_STASH}
            />
          </Route>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const Home = lazy(() => import('@/pages/Home'));
const Login = lazy(() => import('@/pages/Login'));
const InspectionHistory = lazy(() => import('@/pages/Home/InspectionHistory'));
const InspectionHistoryWorkStash = lazy(
  () => import('@/pages/Home/InspectionHistory/WorkStash'),
);
const PerformanceCheck = lazy(() => import('@/pages/Home/PerformanceCheck'));
const CarBasicInfo = lazy(
  () => import('@/pages/Home/PerformanceCheck/CarBasicInfo'),
);
const CarInteriorInfo = lazy(
  () => import('@/pages/Home/PerformanceCheck/CarInteriorInfo'),
);
const CarExteriorInfo = lazy(
  () => import('@/pages/Home/PerformanceCheck/CarExteriorInfo'),
);
const StatusAssessment = lazy(
  () => import('@/pages/Home/PerformanceCheck/StatusAssessment'),
);
const AdditionalInformation = lazy(
  () => import('@/pages/Home/PerformanceCheck/AdditionalInformation'),
);
const NotFound = lazy(() => import('@/pages/NotFound'));
