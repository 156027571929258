import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { IBasicCarInfoForm } from '@/pages/Home/PerformanceCheck/CarBasicInfo/type';
import { ICarExteriorInfo } from '@/pages/Home/PerformanceCheck/CarExteriorInfo/type';
import { ICarInteriorInfo } from '@/pages/Home/PerformanceCheck/CarInteriorInfo/type';
import { IStatusAssessmentFormData } from '@/pages/Home/PerformanceCheck/StatusAssessment/type';

export interface IEditingCarContext {
  editingCar: TEditingCar;
  setEditingCar: React.Dispatch<React.SetStateAction<TEditingCar>>;
}

export interface IEtc {
  translation: {
    en: string;
    ar: string;
    ko: string;
  };
}

export type TEditingCar = {
  basicInfo?: IBasicCarInfoForm;
  internalCarInfo?: ICarInteriorInfo;
  externalCarInfo?: ICarExteriorInfo;
  accidentAssessment?: IStatusAssessmentFormData;
  etc?: IEtc;
  signImageURL?: string;
  userName?: string;
};

const INIT_EDITING_CAR = { editingCar: {}, setEditingCar: () => {} };

const EditingCarContext = createContext<IEditingCarContext>(INIT_EDITING_CAR);

export const useEditingCarContext = () => {
  const editingCar = useContext(EditingCarContext);

  if (!editingCar)
    throw new Error('EditingCarContextProvider를 찾을 수 없습니다!');

  return editingCar;
};

export default function EditingCarContextProvider({
  children,
}: PropsWithChildren) {
  const [editingCar, setEditingCar] = useState<TEditingCar>({});

  return (
    <EditingCarContext.Provider value={{ editingCar, setEditingCar }}>
      {children}
    </EditingCarContext.Provider>
  );
}
